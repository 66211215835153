<template>
  <v-app>
    <v-main id="pages" :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "PagesIndex",
  created() {
    // this.$vuetify.theme.dark = false;
  },

  beforeDestroy() {
    // this.$vuetify.theme.dark = false;
  }
};
</script>
